import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { accountService, alertService } from "../_services";

function sendPin() {
  alertService.clear();
  accountService
    .sendPin()
    .then(() => alertService.success("Pin an Email gesendet."))
    .catch((error) => alertService.error(error));
}

function Pin({ history, location }) {
  const initialValues = {
    pin: "",
  };

  const validationSchema = Yup.object().shape({
    pin: Yup.string().required("Pin ist leer").length(6),
  });

  function onSubmit({ pin }, { setSubmitting }) {
    setSubmitting(true);
    alertService.clear();
    accountService
      .validatePin(pin)
      .then(() => {
        const { from } = location.state || { from: { pathname: "/" } };
        history.push(from);
      })
      .catch((error) => alertService.error(error))
      .finally(() => setSubmitting(false));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <h5 className="card-header">Email-Pin für Zugang erforderlich</h5>
          <div className="card-body">
            <p className="card-text">
              Der HRS Site Master enthält viele vertrauliche Informationen.
            </p>
            <p className="card-text">
              Aus Sicherheitsgründen wird zusätzlich eine PIN benötigt.{" "}
            </p>
            <p className="card-text">
              Klick auf "PIN an Email senden" um den PIN per Email zu erhalten
              und bestätige ihn unten. Bitte prüfe ggf. auch Deinen SPAM-Ordner.
            </p>

            <div className="form-row">
              <div className="form-group col">
                <button
                  type="button"
                  onClick={sendPin}
                  className="btn btn-primary"
                >
                  Pin an Email senden
                </button>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <Field
                  name="pin"
                  type="number"
                  className={
                    "form-control" +
                    (errors.pin && touched.pin ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="pin"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Pin best&auml;tigen
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export { Pin };
